var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.showTooltip)?_c('div',{ref:"svgTooltip",staticClass:"d-flex justify-center align-center pa-2",style:(("position: fixed; top: " + _vm.tooltipY + "px; left: " + _vm.tooltipX + "px;\n          min-width: 100px; min-height: 50px; background: white; border-radius: 15px;\n          opacity: .9; z-index: 5;\n        "))},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")])]):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"mb-0 py-0 px-6",attrs:{"fluid":""}},[_c('v-card',{staticStyle:{"width":"100%","height":"100%"}},[_c('ewc-toolbar',{attrs:{"pending-requests-count":_vm.pendingRequestsCount},scopedSlots:_vm._u([{key:"siteSelection",fn:function(){return [_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('v-select',{staticClass:"mt-7",attrs:{"id":"ewcSiteSelectionDropdown","items":_vm.sites,"label":"Select Site","item-text":"siteName","loading":_vm.loadingSites,"return-object":"","outlined":"","dense":"","flat":""},on:{"change":function (selection) {
                    _vm.showAlert = false
                    _vm.store.set('app/disableEwc', false)
                    _vm.grids = selection.siteGrids
                    if(_vm.grids.length === 1){
                      _vm.selectedFloor = _vm.grids[0]
                      _vm.onSelectFloor(_vm.selectedFloor)
                    } else {
                      _vm.selectedFloor = ''
                      if(_vm.grids.length === 0){
                        _vm.showAlert = true
                        _vm.alertType = 'info'
                        _vm.alertMessage = _vm.aletMessageSiteNotSetUp
                        _vm.store.set('app/disableEwc', true)
                      }
                    }
                  }},model:{value:(_vm.selectedSite),callback:function ($$v) {_vm.selectedSite=$$v},expression:"selectedSite"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('v-select',{staticClass:"mt-7",attrs:{"id":"ewcFloorplanSelectionDropdown","disabled":_vm.loadingFloorplan || !_vm.selectedSite,"loading":_vm.loadingFloorplan,"items":_vm.grids,"label":"Select Floorplan","item-text":"name","return-object":"","outlined":"","dense":"","flat":""},on:{"change":function (selection) {
                    _vm.onSelectFloor(selection)
                  }},model:{value:(_vm.selectedFloor),callback:function ($$v) {_vm.selectedFloor=$$v},expression:"selectedFloor"}})],1)]},proxy:true},{key:"pageSpecificIcons",fn:function(){return [_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"d-flex justify-end"},[(!_vm.editMode)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{style:("\n                          background-color: rgba(92, 176, 255, .90);\n                          width: 20px;\n                          height: 20px;\n                          margin: 5px;\n                    ")}),_c('div',{staticClass:"mr-2"},[_vm._v(" Active Equipment ")]),_c('div',{style:("\n                          background-color: rgba(141, 219, 242, .90);\n                          width: 20px;\n                          height: 20px;\n                          margin: 5px;\n                    ")}),_c('div',{staticClass:"mr-2"},[_vm._v(" Pending Equipment ")]),_c('div',{style:("\n                          background-color: rgba(235, 215, 192, .95);\n                          width: 20px;\n                          height: 20px;\n                          margin: 5px;\n                    ")}),_c('div',{staticClass:"mr-2"},[_vm._v(" Wall ")])]):_vm._e(),(_vm.editMode)?_c('v-btn',{staticClass:"font-weight-bold mr-2",attrs:{"color":"green","text":"","loading":_vm.savingWall,"disabled":!_vm.selectedFloor},on:{"click":_vm.onSaveWall}},[_vm._v(" Save ")]):_vm._e(),(_vm.editMode)?_c('v-btn',{staticClass:"font-weight-bold mr-2",attrs:{"color":"grey","text":"","disabled":!_vm.selectedFloor || _vm.savingWall},on:{"click":function($event){_vm.editMode = !_vm.editMode
                      _vm.selectedWallItems = []
                      _vm.localBus.$emit('clearSelectedSquaresFloor')}}},[_vm._v(" Cancel ")]):_vm._e(),_c('v-btn',{staticClass:"font-weight-bold mr-2",attrs:{"color":"primary","text":!_vm.editMode,"disabled":!_vm.selectedFloor || _vm.loadingFloorplan},on:{"click":_vm.triggerEditMode}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")]),_vm._v(" Edit ")],1)],1)])]},proxy:true}])})],1)],1)],1),(_vm.showAlert)?_c('v-col',{staticClass:"px-6 pb-0",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":_vm.alertType}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1):_vm._e()],1),(_vm.selectedFloor)?_c('v-row',[_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"height":"800px","width":"100%"}},[_c('grid-wrapper',{attrs:{"floorplan":_vm.floorplan,"loading-floorplan":_vm.loadingFloorplan,"local-bus":_vm.localBus,"set-location-dialog":_vm.editMode,"set-floor-location":_vm.editMode,"pending-equipment-ewc":_vm.floorplan.pendingEquipmentEwc,"configure-floor-base-view":true,"configure-floor":_vm.editMode}})],1)])],1):_vm._e(),_c('material-snackbar',_vm._b({staticClass:"ml-12",attrs:{"type":"info","timeout":_vm.snackBarMessage === _vm.snackBarMessageUpdateFloorplan ? -1 : 3000},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},'material-snackbar',( _obj = {}, _obj['bottom'] = true, _obj['left'] = true, _obj ),false),[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" "),(_vm.snackBarMessage === _vm.snackBarMessageUpdateFloorplan)?_c('v-btn',{staticClass:"cyan--text font-weight-bold",attrs:{"color":"white","height":"25"},on:{"click":function($event){_vm.showSnackBar = false
        _vm.refreshEquipment()}}},[_vm._v(" Refresh? ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }