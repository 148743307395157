<template>
  <v-container
    fluid
  >
    <div
      v-if="showTooltip"
      ref="svgTooltip"
      class="d-flex justify-center align-center pa-2"
      :style="`position: fixed; top: ${tooltipY}px; left: ${tooltipX}px;
            min-width: 100px; min-height: 50px; background: white; border-radius: 15px;
            opacity: .9; z-index: 5;
          `"
    >
      <span>
        {{ tooltipText }}
      </span>
    </div>
    <v-row>
      <v-col
        class="pa-0"
        cols="12"
      >
        <v-container
          fluid
          class="mb-0 py-0 px-6"
        >
          <v-card
            style="width: 100%; height: 100%;"
          >
            <ewc-toolbar
              :pending-requests-count="pendingRequestsCount"
            >
              <template v-slot:siteSelection>
                <v-col
                  cols="6"
                  sm="2"
                >
                  <v-select
                    id="ewcSiteSelectionDropdown"
                    v-model="selectedSite"
                    :items="sites"
                    label="Select Site"
                    item-text="siteName"
                    :loading="loadingSites"
                    return-object
                    class="mt-7"
                    outlined
                    dense
                    flat
                    @change=" (selection) => {
                      showAlert = false
                      store.set('app/disableEwc', false)
                      grids = selection.siteGrids
                      if(grids.length === 1){
                        selectedFloor = grids[0]
                        onSelectFloor(selectedFloor)
                      } else {
                        selectedFloor = ''
                        if(grids.length === 0){
                          showAlert = true
                          alertType = 'info'
                          alertMessage = aletMessageSiteNotSetUp
                          store.set('app/disableEwc', true)
                        }
                      }
                    }
                    "
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="2"
                >
                  <v-select
                    id="ewcFloorplanSelectionDropdown"
                    v-model="selectedFloor"
                    :disabled="loadingFloorplan || !selectedSite"
                    :loading="loadingFloorplan"
                    :items="grids"
                    label="Select Floorplan"
                    item-text="name"
                    return-object
                    class="mt-7"
                    outlined
                    dense
                    flat
                    @change=" (selection) => {
                      onSelectFloor(selection)
                    }"
                  />
                </v-col>
              </template>
              <template v-slot:pageSpecificIcons>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <div class="d-flex justify-end">
                    <div
                      v-if="!editMode"
                      class="d-flex align-center"
                    >
                      <div
                        :style="`
                            background-color: rgba(92, 176, 255, .90);
                            width: 20px;
                            height: 20px;
                            margin: 5px;
                      `"
                      />
                      <div class="mr-2">
                        Active Equipment
                      </div>
                      <div
                        :style="`
                            background-color: rgba(141, 219, 242, .90);
                            width: 20px;
                            height: 20px;
                            margin: 5px;
                      `"
                      />
                      <div class="mr-2">
                        Pending Equipment
                      </div>
                      <div
                        :style="`
                            background-color: rgba(235, 215, 192, .95);
                            width: 20px;
                            height: 20px;
                            margin: 5px;
                      `"
                      />
                      <div class="mr-2">
                        Wall
                      </div>
                    </div>
                    <v-btn
                      v-if="editMode"
                      color="green"
                      class="font-weight-bold mr-2"
                      text
                      :loading="savingWall"
                      :disabled="!selectedFloor"
                      @click="onSaveWall"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      v-if="editMode"
                      color="grey"
                      class="font-weight-bold mr-2"
                      text
                      :disabled="!selectedFloor || savingWall"
                      @click="
                        editMode = !editMode
                        selectedWallItems = []
                        localBus.$emit('clearSelectedSquaresFloor')
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="font-weight-bold mr-2"
                      :text="!editMode"
                      :disabled="!selectedFloor || loadingFloorplan"
                      @click="triggerEditMode"
                    >
                      <v-icon>
                        {{ mdiPencil }}
                      </v-icon>
                      Edit
                    </v-btn>
                  </div>
                </v-col>
              </template>
            </ewc-toolbar>
          </v-card>
        </v-container>
      </v-col>
      <v-col
        v-if="showAlert"
        class="px-6 pb-0"
        cols="12"
      >
        <v-alert
          :type="alertType"
          class="mb-0"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="selectedFloor">
      <v-col
        cols="12"
        style="position: relative;"
      >
        <div
          style="height: 800px; width: 100%;"
        >
          <grid-wrapper
            :floorplan="floorplan"
            :loading-floorplan="loadingFloorplan"
            :local-bus="localBus"
            :set-location-dialog="editMode"
            :set-floor-location="editMode"
            :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
            :configure-floor-base-view="true"
            :configure-floor="editMode"
          />
        </div>
      </v-col>
    </v-row>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      :timeout="snackBarMessage === snackBarMessageUpdateFloorplan ? -1 : 3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
      <v-btn
        v-if="snackBarMessage === snackBarMessageUpdateFloorplan"
        color="white"
        class="cyan--text font-weight-bold"
        height="25"
        @click="
          showSnackBar = false
          refreshEquipment()
        "
      >
        Refresh?
      </v-btn>
    </material-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import ewcApi from '@/api/ewc.js'
  import common from '@/api/common.js'
  import ewcHolder from '@/util/ewc-holder'
  import equipmentApi from '@/api/equipment'
  import socketApi from '@/api/websockets'
  import socketUtil from '@/util/websockets'
  import messageHolder from '@/util/message-holder'
  import store from '@/store'
  import { mdiPencil } from '@mdi/js'

  export default {
    name: 'EWCConfigureFloor',
    data: () => ({
      localBus: new Vue(),
      sites: [],
      grids: [],
      selectedSite: '',
      selectedFloor: '',
      loadingSites: false,

      floorplan: {},
      loadingFloorplan: false,

      editMode: false,
      selectedWallItems: [],

      savingWall: false,

      showAlert: false,
      alertType: 'error',
      alertMessage: '',
      alertMessageDataCenters: 'There was an error loading Data Centers. Please try again later.',
      alertMessageFloorplan: 'There was an error loading Floorplan. Please try again later.',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',
      alertMessageNoFloorplans: 'Your Sites are currently not set up with Floorplans for use with EWC. Please contact us if you would like more information about this feature.',
      aletMessageSiteNotSetUp: 'This site is currently not set up with Floorplans for use with EWC. Please contact us if you are interested in adding this site to EWC.',
      alertMessageFailSaveWall: 'There was an error saving changes to the floor configuration. Please try again later.',

      showSnackBar: false,
      snackBarMessage: '',
      snackBarMessageUpdateFloorplan: 'The currently selected equipment floorplan has been updated:',
      snackBarMessageSuccessfulWallUpdate: 'Successfully updated floor configuration!',

      updateSvg: -1,

      showTooltip: false,
      tooltipY: 0,
      tooltipX: 0,
      tooltipText: '',

      ewcHolder: ewcHolder,

      tooltips: false,

      store: store,

      mdiPencil: mdiPencil,

    }),

    computed: {
      pendingRequestsCount () {
        if (Object.keys(this.floorplan).length && !this.loadingFloorplan) {
          return this.floorplan.equipmentRequests.length
        } else return 0
      },

    },

    created () {
      ewcHolder.getDataCenters() ? this.sites = ewcHolder.getDataCenters() : this.getDataCentersForUser()
      this.getSocketNotificationUrl()

      if (ewcHolder.getLatestSiteAndFloorSelection()) {
        this.selectedSite = ewcHolder.getLatestSiteAndFloorSelection().site
        this.grids = this.selectedSite.siteGrids
        this.selectedFloor = ewcHolder.getLatestSiteAndFloorSelection().floor
      }

      if (this.selectedFloor) { this.getEquipmentEwcForSite(this.selectedFloor.id) }

      this.localBus.$on('display-tooltip', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = label
      })
      this.localBus.$on('display-tooltip-grid-square', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = `${this.floorplan.dataCenterGrid.ylabels[label.y]} - ${this.floorplan.dataCenterGrid.xlabels[label.x]}`
      })
      this.localBus.$on('hide-tooltip', () => {
        this.showTooltip = false
      })

      this.localBus.$on('selectedItemsResponse', (selectedItems, selectedPartialItems) => {
        this.selectedWallItems = selectedItems
      })

      if (messageHolder.getMessage()) {
        this.showSnackBar = true
        this.snackBarMessage = messageHolder.getMessage()
        messageHolder.setMessage('')
      }
    },

    methods: {
      getDataCentersForUser () {
        if (ewcHolder.getDataCenters()) {
          this.sites = ewcHolder.getDataCenters()
          if (this.sites.length === 1) {
            this.selectedSite = this.sites[0]
            this.grids = this.selectedSite.siteGrids
          }
          return
        }
        this.loadingSites = true
        ewcApi.getDataCentersForUser()
          .then(response => {
            console.log(response)
            this.sites = response.data
            if (this.checkForFloorPlans(this.sites)) {
              if (this.sites.length === 1) {
                this.selectedSite = this.sites[0]
                this.grids = this.selectedSite.siteGrids
              }
              store.set('app/disableEwc', false)
            } else {
              this.showAlert = true
              this.alertType = 'info'
              this.alertMessage = this.alertMessageNoFloorplans
              store.set('app/disableEwc', true)
            }
            this.loadingSites = false
            ewcHolder.setDataCenters(response.data)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getDataCentersForUser()
            } else {
              this.loadingSites = false
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageDataCenters
            }
          })
      },
      checkForFloorPlans (sites) {
        let floorplansPresent = false
        sites.forEach(site => {
          if (site.siteGrids.length > 0) {
            floorplansPresent = true
          }
        })
        return floorplansPresent
      },

      getEquipmentEwcForSite (gridId) {
        this.loadingFloorplan = true
        const floorplan = ewcHolder.getFloorplan()
        if (floorplan?.dataCenterGrid.id === parseInt(gridId) && !this.savingWall) {
          if (floorplan.activeEquipmentEwc.length > 100) {
            setTimeout(() => {
              this.loadingFloorplan = false
              this.$nextTick(() => { this.floorplan = floorplan })
            }, [0])
          } else {
            this.loadingFloorplan = false
            this.floorplan = floorplan
          }
        } else {
          equipmentApi.getEquipmentEwcForSite(gridId)
            .then((response) => {
              console.log(response)
              this.loadingFloorplan = false
              this.$nextTick(() => { this.floorplan = response.data })
              ewcHolder.setFloorplan(response.data)
            })
            .catch(async (error) => {
              console.log(error)
              if (await common.handleBadCall(error, this.$router) === true) {
                this.getEquipmentEwcForSite(gridId)
              } else {
                this.loadingFloorplan = false
                this.showAlert = true
                this.alertType = 'error'
                this.alertMessage = this.alertMessageFloorplan
                this.selectedFloor = ''
              }
            })
        }
      },
      refreshEquipment () {
        this.loadingFloorplan = true
        if (!this.selectedFloor) return
        equipmentApi.getEquipmentEwcForSite(this.selectedFloor.id)
          .then((response) => {
            console.log(response)
            this.loadingFloorplan = false
            this.$nextTick(() => { this.floorplan = response.data })
            ewcHolder.setFloorplan(response.data)
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.refreshEquipment()
            } else {
              this.loadingFloorplan = false
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageFloorplan
              this.selectedFloor = ''
            }
          })
      },

      onSelectFloor (selection) {
        this.showAlert = false

        ewcHolder.setLatestSiteAndFloorSelection({ site: this.selectedSite, floor: this.selectedFloor })
        this.getEquipmentEwcForSite(selection.id)
      },

      triggerEditMode () {
        if (this.editMode) return
        this.editMode = true
        this.localBus.$emit('clearSelectedItems')
        this.localBus.$emit('initiate-edit-wall')
      },

      onSaveWall () {
        this.savingWall = true
        let skipFinallyBlock = false
        const wallArray = []
        this.selectedWallItems.forEach(wallItem => {
          wallArray.push(`${wallItem.x}-${wallItem.y}`)
        })
        ewcApi.updateEwcWalls(this.selectedFloor.id, JSON.stringify(wallArray))
          .then(response => {
            console.log(response)
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageSuccessfulWallUpdate
            this.getEquipmentEwcForSite(this.selectedFloor.id)
            this.editMode = false
          }).catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.onSaveWall()
            } else {
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageFailSaveWall
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.savingWall = false
            }
          })
      },

      getSocketNotificationUrl () {
        socketApi.getSocketNotificationUrl()
          .then(response => {
            console.log(response)

            socketUtil.setupSockets(response.data, this.onRecieveSocketMessage)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getSocketNotificationUrl()
            }
          })
      },
      onRecieveSocketMessage (message) {
        console.log(message)
        const parsedMessage = JSON.parse(message.body)

        if (parsedMessage.messageType === 'UPDATE_FLOOR_ITEM' && parsedMessage.gridId === this.selectedFloor.id) {
          this.showSnackBar = true
          this.snackBarMessage = this.snackBarMessageUpdateFloorplan
        }
      },
    },
  }
</script>
